<template>
  <div class="m-5 p-3">
    <el-row>
      <el-col :md="15" class="pr-4">
        <div>
          <h2>{{ $t("SATCoursePart2.title") }}</h2>
          <br />
          <h5 style="font-weight:bold;font-style: italic">
            {{ $t("SATCoursePart2.sub-title") }}
          </h5>
          <div>{{ $t("SATCoursePart2.desc1") }}</div>
          <br />
          <div>{{ $t("SATCoursePart2.desc2") }}</div>
        </div>
      </el-col>
      <el-col :md="9" class="img">
        <el-image
          src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/images/course/sat_part2.jpg"
        />
      </el-col>
    </el-row>
    <div class="mt-4">
      <h4 style="font-weight:bold;">{{ $t("SATCoursePart2.schedule") }}</h4>
      <ul>
        <li>{{ $t("SATCoursePart2.s1") }}</li>
        <li>{{ $t("SATCoursePart2.s2") }}</li>
        <li>{{ $t("SATCoursePart2.s3") }}</li>
        <li>{{ $t("SATCoursePart2.s4") }}</li>
        <li>{{ $t("SATCoursePart2.s5") }}</li>
      </ul>
    </div>
    <div class="mt-4">
      <h4 style="font-weight:bold;">{{ $t("SATCoursePart2.group") }}</h4>
      <ul>
        <li v-html="$t('SATCoursePart2.g1')"></li>
        <li v-html="$t('SATCoursePart2.g2')"></li>
        <li v-html="$t('SATCoursePart2.g3')"></li>
      </ul>
    </div>
    <div class="mt-4">
      <h4 style="font-weight:bold;">{{ $t("SATCoursePart2.bonus") }}</h4>
      <ul>
        <li v-html="$t('SATCoursePart2.b1')"></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.img {
  padding-top: 108px;
  padding-left: 20px;
}

@media (max-width: 992px) {
  .img {
    padding-top: 20px;
    padding-left: 20px;
  }
}
</style>
